html, body { 
    height: 100%; 
}
body { 
    margin: 0;
}

h1 {
    text-align: center;
    margin: 16px 0 16px !important;
}



/* Custom styles */

.content-header {
    margin-top: 2rem;
    margin-bottom: 2rem;
    
    span.title {
        font-size: 1.2rem;
        font-weight: 500;
    }

    button.new {
        margin-right:.5rem;
    }
}


.ribbon-dev {
    width: 300px;
    top: 25px;
    left: -100px;
    text-align: center;
    padding: 10px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #f0f0f0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    z-index: 9999;
    position: fixed;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    background: rgba(255, 71, 71, 0.8);
    background-color: rgba(255, 71, 71, 0.8);
    pointer-events: none;
}
